import { Button } from "@chakra-ui/react";
import { FiSave } from "react-icons/fi";

export default function SaveButton({ uploadData, isButtonDisabled }) {
  return (
    <Button
      onClick={uploadData}
      isDisabled={isButtonDisabled}
      colorScheme="blue"
      mt={4}
      px={6}
      py={2}
      size="sm"
      leftIcon={<FiSave />}
    >
      Save
    </Button>
  );
}
