import { useAccount, useMsal } from "@azure/msal-react";
import {
  Button,
  Flex,
  Heading,
  Img,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { PRODUCT_NAME } from "../../../Constants";
import UserMenu from "../UserMenu";
import logo from "./../../../logo/logo.png";

export default function Header({
  dataSaved,
  prettySheets,
  activeEnv,
  setActiveEnv,
}) {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [fromWhere, setFromWhere] = useState("");

  const [temp, setTemp] = useState([]);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const { pathname } = useLocation();
  const [id_sheet] = useState(
    pathname
      ? pathname === "/PlanvidaDashboard"
        ? 1
        : parseInt(pathname.split("-")[1])
      : 1
  );
  const navigate = useNavigate();
  const handleSelectedOptionChange = (event) => {
    if (!dataSaved) {
      setTemp(
        prettySheets.filter(
          (value) => value[0] === parseInt(event.target.value)
        )[0]
      );
      setFromWhere("dropdown");
      onModalOpen();
    } else {
      setTemp(
        prettySheets.filter(
          (value) => value[0] === parseInt(event.target.value)
        )[0]
      );
      
      navigate(
        "/" +
          prettySheets
            .filter((value) => value[0] === parseInt(event.target.value))[0][1]
            .replaceAll(" ", "") +
          "-" +
          prettySheets.filter(
            (value) => value[0] === parseInt(event.target.value)
          )[0][0]
      );
    }
  };

  const onModalOkClick = () => {
    if (fromWhere === "dropdown") {
      navigate("/" + temp[1].replaceAll(" ", "") + "-" + temp[0]);
    } else if (fromWhere === "prodEnv") {
      setActiveEnv(["Production Environment"]);
    } else if (fromWhere === "userMenu") {
      sessionStorage.clear();
      instance.logout();
      navigate("/");
    } else if (fromWhere === "Planvida") {
      navigate("/PlanvidaDashboard");
    }
  };

  const NavLink = ({ children, dataSave, activeEnv, setActiveEnv }) => {
    const isActive = children === activeEnv[0];
    const handleClick = () => {
      if (!dataSave) {
        setFromWhere(
          children === "Planvida Dashboard"
            ? "Planvida"
            : children === "Production Environment"
            ? "prodEnv"
            : "simEnv"
        );
        onModalOpen();
      } else {
        if (children !== "Planvida Dashboard") {
          setActiveEnv([children]);
        } else {
          navigate("/PlanvidaDashboard");
        }
      }
    };

    return (
      <Link
        onClick={handleClick}
        px={4}
        fontSize="md"
        fontWeight={isActive ? "bold" : "normal"}
        textDecorationLine={isActive ? "underline" : ""}
        _hover={{ textDecoration: "none", textDecorationLine: "underline" }}
      >
        {children}
      </Link>
    );
  };

  return (
    <>
      <Flex
        as="nav"
        bg="blackAlpha.900"
        color="white"
        py={2}
        px={4}
        alignItems="center"
      >
        <Img
          src={logo}
          backgroundColor="transparent"
          style={{ height: "5vh" }}
          className="App-logo"
        />
        <Heading size="sm">&nbsp;&nbsp;{PRODUCT_NAME}</Heading>
        <Spacer />
        <NavLink
          dataSave={dataSaved}
          activeEnv={activeEnv}
          setActiveEnv={setActiveEnv}
        >
          Planvida Dashboard
        </NavLink>
        {prettySheets.length > 0 && activeEnv && (
          <Select
            width={"250px"}
            icon={<IoMdArrowDropdown />}
            iconColor="black"
            bg={"whiteAlpha.900"}
            style={{ color: "black", border: "white" }}
            onChange={handleSelectedOptionChange}
          >
            <option selected hidden disabled value="">
              {
                prettySheets.filter(
                  (value) => value[0] === parseInt(id_sheet)
                )[0][1]
              }
            </option>
            {prettySheets &&
              prettySheets.map((value, index) => (
                <option
                  key={value[0] + value[1]}
                  value={value[0]}
                  style={{ color: "black" }}
                >
                  {value[1]}
                </option>
              ))}
          </Select>
        )}
        <NavLink
          dataSave={dataSaved}
          activeEnv={activeEnv}
          setActiveEnv={setActiveEnv}
        >
          Production Environment
        </NavLink>
        {/* User profile */}
        <UserMenu
          dataSave={dataSaved}
          onModalOpen={onModalOpen}
          setFromWhere={setFromWhere}
          instance={instance}
          account={account}
        />
      </Flex>

      <Modal onClose={onModalClose} isOpen={isModalOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alert</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            There are unsaved changes.All the changes would be lost if you
            navigate.
          </ModalBody>
          <ModalFooter gap={3}>
            <Button colorScheme="red" variant="outline" onClick={onModalClose}>
              Go Back
            </Button>
            <Button
              colorScheme="green"
              variant="outline"
              onClick={onModalOkClick}
            >
              Discard Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
