import { AuthenticatedTemplate, useAccount, useMsal } from "@azure/msal-react";
import { Box, Button, Container, Flex, HStack, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetchWithMsal";
import { CustomSpinner } from "../../Spinner";
import Header from "./Header";
import { SlSizeFullscreen } from "react-icons/sl";


export default function PlanvidaDashboard() {
  const [isDataSaved, setIsDataSaved] = useState(true);
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const account = useAccount(accounts[0] || {});
  const [activeEnv, setActiveEnv] = useState(null);
  const [prettySheets, setPrettySheets] = useState();
  const [{ loading, error }, fetchData] = useFetch([]);
  const planVidaIframeRef = useRef(null);

  useEffect(() => {
    const keyDownEventHandler = async (keydownEvt) => {
        if (keydownEvt.code === 'Escape') {
            if (document.fullscreenElement) {
                await document.exitFullscreen();
            }
        }
    }

    document.addEventListener('keydown', keyDownEventHandler);

    return () => document.removeEventListener('keydown', keyDownEventHandler);
});

  useEffect(() => {
    if (!account) {
      navigate("/");
    } else if (
      account.idTokenClaims.roles.includes("dpplc_user") ||
      account.idTokenClaims.roles.includes("Pretty_Litter")
    ) {
      if (sessionStorage.getItem("prettySheets")) {
        var prettyLitterSheets = JSON.parse(
          sessionStorage.getItem("prettySheets")
        );
        setPrettySheets(prettyLitterSheets);
        let aci = JSON.parse(sessionStorage.getItem("acEnv"));
        if (aci) {
          setActiveEnv(aci);
        } else {
          setActiveEnv(prettyLitterSheets[0]);
          sessionStorage.setItem(
            "acEnv",
            JSON.stringify(["Production Environment"])
          );
        }
      } else {
        fetchData(`/get-data-pretty-sheets`, { method: "GET" }).then((data) => {
          setPrettySheets(data);
          sessionStorage.setItem("prettySheets", JSON.stringify(data));
          let aci = JSON.parse(sessionStorage.getItem("acEnv"));
          if (aci) {
            setActiveEnv(aci);
          } else {
            setActiveEnv("Production Environment");
            sessionStorage.setItem(
              "acEnv",
              JSON.stringify(["Production Environment"])
            );
          }
        });
      }
      setIsDataSaved(true);
    }
  }, [
    account,
    navigate,
    setIsDataSaved,
    setActiveEnv,
    setPrettySheets,
    fetchData,
  ]);

  const onFullscreenBtnClickHandler = async (event) => {
    try {
        // Check if fullscreen mode is available
        if (document.fullscreenEnabled) {
            // Go into fullscreen mode
            await planVidaIframeRef.current?.requestFullscreen();

        } else {
            alert('Your browser does not support fullscreen mode.');
        }

    } catch(error) {
        console.error(error);
        alert('Something went wrong.');
    }
}

  if (error) return <p>Follwing Error has occurred {error}</p>;
  if (loading || !activeEnv)
    return (
      <Container
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          className="Loading-Data"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          boxShadow="md"
          backgroundColor={"white"}
        >
          <CustomSpinner text="Loading Your Data.. Please Wait" />
        </Box>
      </Container>
    );

  return (
    <AuthenticatedTemplate>
      <Box minHeight="100vh">
        <Header
          dataSaved={isDataSaved}
          prettySheets={prettySheets}
          activeEnv={activeEnv}
          setActiveEnv={setActiveEnv}
        />
        <Flex flex="1" flexDirection="column">
      <Container maxW="container.xxl" py={8}>
        <Stack spacing={8}>
          <Tabs variant="enclosed" bg="white" boxShadow="md">
            <TabList>
              <Tab>
                Planvida Dashboard
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
              <HStack spacing={3}>
                  <Button
                    style={{ marginTop: "16px", width: "130px" }}
                    fontSize="md"
                    fontWeight="bold"
                    onClick={onFullscreenBtnClickHandler}
                    colorScheme="blue"
                    leftIcon={<SlSizeFullscreen/>}
                  >
                    Full Screen
                  </Button>
                  </HStack>
                <Box minHeight="10vh" paddingBottom={10}>
                  <Box
                    maxHeight="60vh"
                    overflow="auto"
                    textAlign="center"
                    id="printableArea"
                    className="react-grid"
                  >
                     <iframe 
                     title="Planvida Inventory Analytics PrettyLitter Web Integrated" 
                     ref={planVidaIframeRef}
                     width="100%" 
                     height="420" 
                     src="https://app.powerbi.com/reportEmbed?reportId=8661e9d8-6c87-4df3-89cb-04cbcaabbf85&autoAuth=true&ctid=57cec8c2-deb1-4ba8-8213-f12770d035ed" 
                     allowFullScreen={true}>
                     </iframe>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Container>
    </Flex>
      </Box>
    </AuthenticatedTemplate>
  );
}
