import { AuthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { Box, Container } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductSupplySKUIntRows, ProductSupplySkuBlankRows, ProductSupplySkuColumnsDatabase, ProductSupplySkuDependenciesMapping, ProductSupplySkuDropDownMasterColumnsMapping, ProductSupplySkuDropDownMasters, ProductSupplySkuDropdownRows, ProductSupplySkuNonEditableRows, ProductSupplySkuRowMapping, ProductSupplySkuRows } from '../RegionalCanvasHelper';
import useFetch from './../../../../hooks/useFetchWithMsal';
import { CustomSpinner } from './../../../Spinner';
import Header from './../Header';
import DropDownTables from "./../WarehouseSpace/WarehouseSpaceContent";
import './../style.scss';

export default function ProductSupplySkuMapping() {
  const [isDataSaved,setIsDataSaved] = useState(true)
  const {accounts } = useMsal();
  const navigate= useNavigate()
  const account = useAccount(accounts[0] || {});
  const [activeEnv,setActiveEnv] = useState(null)
  const [prettySheets,setPrettySheets] = useState()
  const [{ loading, error}, fetchData] = useFetch();


  useEffect(()=> {
    if (!account) {
      navigate("/")
  }
  else if (account.idTokenClaims.roles.includes("dpplc_user")||account.idTokenClaims.roles.includes("Pretty_Litter"))
  {
   if (sessionStorage.getItem("prettySheets")) {  
      var prettyLitterSheets=JSON.parse(sessionStorage.getItem("prettySheets"))
      setPrettySheets(prettyLitterSheets);
      let aci = JSON.parse(sessionStorage.getItem("acEnv"))
      if (aci) {
        setActiveEnv(aci)
      }
      else {
        setActiveEnv(prettyLitterSheets[0])
        sessionStorage.setItem("acEnv",JSON.stringify(["Production Environment"]))
      }
    }
    else {
      fetchData(`/get-data-pretty-sheets`, { method: "GET" }).then((data) => {
        setPrettySheets(data)
        sessionStorage.setItem("prettySheets",JSON.stringify(data))
        let aci = JSON.parse(sessionStorage.getItem("acEnv"))
        if (aci) {
          setActiveEnv(aci)          
        }
        else {
          setActiveEnv("Production Environment")
          sessionStorage.setItem("acEnv",JSON.stringify(["Production Environment"]))
        }
      })
    } 
    setIsDataSaved(true)
  }
  },[account,navigate,setIsDataSaved,setActiveEnv,setPrettySheets,fetchData])

  if (error) return (<p>Follwing Error has occurred {error}</p>)
  if (loading || !activeEnv) return (
      <Container minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      < Box
          className="Loading-Data"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          boxShadow="md"
          backgroundColor={"white"}
      >
          <CustomSpinner text="Loading Your Data.. Please Wait" />
      </Box >
      </Container>
  )
  
  return (
    <AuthenticatedTemplate>
    <Box minHeight="100vh">
     <Header dataSaved={isDataSaved} prettySheets = {prettySheets} activeEnv = {activeEnv} setActiveEnv={setActiveEnv} /> 
     <DropDownTables
        setDataSaved={setIsDataSaved} ActiveEnv={activeEnv} 
        CustomerMasterRows={ProductSupplySkuRows} CustomerRowMapping={ProductSupplySkuRowMapping} 
        SupplySkuNonEditableRows={ProductSupplySkuNonEditableRows}  SupplySkuColumnsDatabase={ProductSupplySkuColumnsDatabase} 
        DropDownRows={ProductSupplySkuDropdownRows} DropDownMasters={ProductSupplySkuDropDownMasters} DropDownMasterColumnsMapping={ProductSupplySkuDropDownMasterColumnsMapping}
        dependenciesMapping={ProductSupplySkuDependenciesMapping}  blankRows={ProductSupplySkuBlankRows} DropDownMasterIntRows={ProductSupplySKUIntRows}
        retrieveURL={'/get-product-supply-sku-mapping'} updateURL={'/update-master'} deleteURL={'/delete-master'} 
      />
    </Box>
    </AuthenticatedTemplate>
  );

}
