import {
  Button,
  Checkbox,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  IoChevronDownCircleOutline,
  IoChevronUpCircleOutline,
} from "react-icons/io5";
import { IoFilterOutline } from "react-icons/io5";

const SlicerDropdown = ({
  label,
  selectedValues,
  allValues,
  onValueChange,
}) => {
  const [filterText, setFilterText] = useState("");
  const [filteredValues, setFilteredValues] = useState([...allValues]);

  /**
   * Slicer checkbox change handler.
   * @param {*} value Slicer value.
   */
  const onSlicerCheckboxChange = (value) => {
    let newSelectedValues = structuredClone(selectedValues);
    const valueIdx = newSelectedValues.indexOf(value);

    if (valueIdx >= 0) {
      newSelectedValues.splice(valueIdx, 1);
    } else {
      newSelectedValues.push(value);
    }

    if (onValueChange) {
      onValueChange(newSelectedValues);
    }
  };

  /**
   * Selects / unselects all the values of a slicer.
   */
  const selectOrUnselectAllValues = () => {
    const newSelectedValues =
      selectedValues.length < allValues.length ? [...allValues] : [];
    if (onValueChange) {
      onValueChange(newSelectedValues);
    }
  };

  /**
   * Filters slicer values based on input text
   */
  const filterValues = useCallback(() => {
    setFilteredValues((prevFilteredSlicers) => {
      let newFilteredValues = [...allValues];

      if (filterText.length) {
        newFilteredValues = allValues.filter((value) =>
          value.toLowerCase().includes(filterText.toLowerCase())
        );
      } else {
        newFilteredValues = [...allValues];
      }

      return [...newFilteredValues];
    });
  }, [allValues, filterText]);

  // Filter slicers 500 ms after entering filter text
  useEffect(() => {
    const filterTimeoutId = setTimeout(() => filterValues(), 500);
    return () => clearTimeout(filterTimeoutId);
  }, [filterText, filterValues]);

  return (
    <Menu closeOnSelect={false} key={`slicer-${label}`}>
      {({ isOpen }) => (
        <>
          <Tooltip label={`Filter by ${label}`} placement="top">
            <MenuButton
              as={Button}
              leftIcon={<IoFilterOutline />}
              rightIcon={
                isOpen ? (
                  <IoChevronUpCircleOutline />
                ) : (
                  <IoChevronDownCircleOutline />
                )
              }
              style={{ marginTop: "16px" }}
              size="sm"
              placeholder={label}
              colorScheme="blue"
            >
              {label}
            </MenuButton>
          </Tooltip>

          <MenuList zIndex="sticky" maxHeight="30vh" overflowY="scroll">
            <Input
              style={{
                marginBottom: "2px",
                width: "90%",
                border: "1px solid gray",
              }}
              size="sm"
              type="text"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            ></Input>

            <MenuItem onClick={selectOrUnselectAllValues}>
              Select / Unselect All
            </MenuItem>

            {filteredValues.map((value, idx) => (
              <MenuItem key={`${label}-${value}-${idx}`}>
                <Checkbox
                  value={value}
                  onChange={(e) => onSlicerCheckboxChange(e.target.value)}
                  isChecked={selectedValues.indexOf(value) >= 0}
                >
                  {value}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default SlicerDropdown;
