import { useAccount, useMsal } from "@azure/msal-react";
import {
  Box,
  Container,
  Flex,
  HStack,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip
} from "@chakra-ui/react";
import { ReactGrid } from "@silevis/reactgrid";
import React, { useCallback, useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { RiDeleteRow } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
// import * as FileSaver from "file-saver";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { BsCalendar2X, BsCardList } from "react-icons/bs";
import {
  IoAddCircleOutline
} from "react-icons/io5";
import { RxReset } from "react-icons/rx";

import { findMaxId, findMaxKey } from "../../../../Constants";
import useFetch from "../../../../hooks/useFetchWithMsal";
import SaveButton from "../../../Save";
import { CustomSpinner } from "../../../Spinner";
import {
  FormatDateToMonthYear1,
  getCellClassName,
} from "../RegionalCanvasHelper";
import RegionalLeaderTable from "../RegionalLeaderTable";
// import TextWrapRenderer from './TextWrapRenderer';
import ChangeLog from "../../../ChangeLog/ChangeLog";
import CustomAlertDialogBox from "../../../CustomAlertDialogBox/CustomAlertDialogBox";
import SlicerDropdown from "../../../SlicerDropdown";
import "../style.scss";

export default function WarehouseCapacityConstraintsContent({
  setDataSaved,
  ActiveEnv,
  CustomerMasterRows1,
  CustomerRowMapping1,
  SupplySkuNonEditableRows,
  SupplySkuColumnsDatabase,
  DropDownRows,
  DropDownMasters,
  DropDownMasterColumnsMapping,
  dependenciesMapping,
  slicerColumns = [],
  retrieveURL,
  updateURL,
  deleteURL,
}) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { pathname } = useLocation();
  const [int1, setInt] = useState(0);
  const prettySheets = JSON.parse(sessionStorage.getItem("prettySheets"));
  const [id_sheet] = useState(pathname ? parseInt(pathname.split("-")[1]) : 0);
  const navigate = useNavigate();
  const [dataGrouped, setDataGrouped] = useState({});
  const [dataGroupedOriginal, setDataGroupedOriginal] = useState({});
  const [DropDownMaster, setDropDownMaster] = useState({});
  const arrayofkeys = Object.keys(dataGrouped);
  const [maxID, setMaxID] = useState(
    arrayofkeys.length === 0 ? 0 : findMaxId(Object.entries(dataGrouped), "id")
  );
  const [maxIDInDB, setMaxIDInDB] = useState(
    arrayofkeys.length === 0
      ? 0
      : findMaxId(Object.entries(dataGroupedOriginal), "id")
  );
  const [datesOriginal, setDatesOriginal] = useState([]);
  const [{ loading, error }, fetchData] = useFetch();
  const [, updateData] = useFetch([]);
  const [, deleteData] = useFetch([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [changesTracker, setChangesTracker] = useState({});
  const [Dates, setDates] = useState([]);
  const [toAddDate, SetToAddDate] = useState(null);
  const [deleteCheckBox, setDeleteCheckBox] = useState(false);
  const [deleteCheckBoxColumns, setDeleteCheckBoxColumns] = useState(false);
  const [deleteTracker, setDeleteTracker] = useState({});
  const [comment, setComment] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPrevious, setShowPrevious] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsCopy, setRowsCopy] = useState([]);
  const [gridData, setGridData] = useState({});
  const [slicerCols, setSlicerCols] = useState(slicerColumns);
  const [editedCellHighlights, setEditedCellHighlights] = useState([]);

  const emptySlicers = Object.fromEntries(slicerCols.map((e) => [e.field, []]));
  const [slicers, setSlicers] = useState({ ...emptySlicers });
  const [selectedSlicers, setSelectedSlicers] = useState({ ...emptySlicers });

  // Alert box state
  const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
  const [alertBoxType, setAlertBoxType] = useState(null);

  // References
  const gridContainerRef = useRef(null);
  const toast = useToast();
  const toastIdRef = useRef();

  const getOptionType = useCallback(
    (DropDownMaster) => {
      if (Object.keys(DropDownMaster).length === 0) return {};
      var masterMappingDict = {};
      DropDownMasters.forEach((masterMappings) => {
        masterMappingDict[masterMappings] = Object.entries(
          DropDownMaster[masterMappings]
        ).map(([key, value]) => {
          return { label: `${key}`, value: `${key}` };
        });
      });
      return masterMappingDict;
    },
    [DropDownMasters]
  );

  const [optionType, setOptionType] = useState({});

  const handleChanges = (changes) => {
    const CustomerMasterRows = [...CustomerMasterRows1, ...Dates];
    const CustomerRowMapping = CustomerRowMapping1;
    Dates.forEach((date_value) => {
      CustomerRowMapping[date_value] = "Proportion_" + date_value;
    });
    setInt(int1 + 1);
    setIsButtonDisabled(false);
    setDataSaved(false);
    if (
      changes[0].type === "dropdown" &&
      changes[0].newCell.text === changes[0].previousCell.text
    ) {
      setRows((rowsTemp) => {
        let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId);
        dataRow["cells"][
          columns.findIndex(
            (columnId) => columnId["columnId"] === changes[0]["columnId"]
          )
        ]["isOpen"] = changes[0].newCell.isOpen;
        return [...rowsTemp];
      });
    } else if (
      changes[0].type === "checkbox" &&
      changes[0].newCell.checked !== changes[0].previousCell.checked
    ) {
      setRows((rowsTemp) => {
        let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId);
        dataRow["cells"][
          columns.findIndex(
            (columnId) => columnId["columnId"] === changes[0]["columnId"]
          )
        ]["checked"] = changes[0].newCell.checked;
        return [...rowsTemp];
      });
      if (Dates.findIndex((value) => value === changes[0].columnId) !== -1) {
        setDeleteTracker((prevData) => {
          var updatedData = prevData;
          var columnstodelete = updatedData["columnstodelete"]
            ? updatedData["columnstodelete"]
            : [];
          if (changes[0].newCell.checked) {
            if (
              columnstodelete.findIndex(
                (value) => value === changes[0].columnId
              ) === -1
            ) {
              columnstodelete.push(changes[0].columnId);
            }
          } else {
            const indexToRemove = columnstodelete.indexOf(changes[0].columnId);
            if (indexToRemove !== -1) {
              columnstodelete.splice(indexToRemove, 1);
            }
          }
          updatedData["columnstodelete"] = columnstodelete;
          return updatedData;
        });
      } else {
        setDeleteTracker((prevData) => {
          var updatedData = prevData;
          var rowstodelete = updatedData["rowstodelete"]
            ? updatedData["rowstodelete"]
            : [];
          if (changes[0].newCell.checked) {
            if (rowstodelete.indexOf(changes[0].rowId) === -1) {
              rowstodelete.push(changes[0].rowId);
            }
          } else {
            const indextoremove = rowstodelete.indexOf(changes[0].rowId);
            if (indextoremove !== -1) {
              rowstodelete.splice(indextoremove, 1);
            }
          }
          updatedData["rowstodelete"] = rowstodelete;
          return updatedData;
        });
      }
    } else {
      if (
        (changes[0].newCell.text === "" || changes[0].newCell.text === null) &&
        Dates.findIndex((value) => value === changes[0].columnId) === -1
      ) {
        const toastSettings = {
          description: changes[0].columnId + " cannot be left blank",
          status: "error",
          isClosable: true,
        };
        if (toastIdRef.current) {
          toast.update(toastIdRef.current, toastSettings);
        } else {
          toast(toastSettings);
        }
      } else {
        var dataTemp = dataGrouped;
        changes.forEach((change) => {
          if (parseInt(change.rowId) > maxID) {
            let dictionaryToSet = {};

            dictionaryToSet[`${CustomerRowMapping["ID"]}`] = parseInt(
              change.rowId
            );
            if (
              CustomerMasterRows1.findIndex(
                (value987) => value987 === change.columnId
              ) === -1
            ) {
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.value;
            } else {
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.text;
            }
            if (
              DropDownRows.findIndex((value) => value === change.columnId) !==
              -1
            ) {
              dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                let mainDict =
                  DropDownMaster[
                    DropDownMasterColumnsMapping[
                      CustomerRowMapping[change.columnId]
                    ]
                  ];
                dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]
                  ? mainDict[change.newCell.text][keyofmapping]
                    ? mainDict[change.newCell.text][keyofmapping]
                    : ""
                  : dataGroupedOriginal[change.rowId][keyofmapping]
                  ? dataGroupedOriginal[change.rowId][keyofmapping]
                  : "";
              });
            }

            var Poer = change.columnId;
            CustomerMasterRows.filter(
              (value) => !(value === Poer || value === "ID")
            ).forEach((value) => {
              dictionaryToSet[CustomerRowMapping[value]] = "";
            });
            dataTemp[parseInt(change.rowId)] = dictionaryToSet;
            setChangesTracker((prevChanges) => {
              const updatedChanges = { ...prevChanges };
              let newCustomer = change.rowId > maxIDInDB ? true : false;
              const key = `${ActiveEnv},${change.rowId},${newCustomer}`;
              updatedChanges[key] = { ...dictionaryToSet };
              let newDatesAdded = [];
              if (
                Dates.findIndex((values) => values === change.columnId) !== -1
              ) {
                newDatesAdded.push(change.columnId);
              }
              updatedChanges[key]["newDatesAdded"] = newDatesAdded;
              return updatedChanges;
            });
          } else {
            let dictionaryToSet = dataTemp[change.rowId];
            if (
              CustomerMasterRows1.findIndex(
                (value987) => value987 === change.columnId
              ) === -1
            ) {
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.value;
            } else {
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.text;
            }
            if (
              DropDownRows.findIndex((value) => value === change.columnId) !==
              -1
            ) {
              dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                let mainDict =
                  DropDownMaster[
                    DropDownMasterColumnsMapping[
                      CustomerRowMapping[change.columnId]
                    ]
                  ];
                dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]
                  ? mainDict[change.newCell.text][keyofmapping]
                    ? mainDict[change.newCell.text][keyofmapping]
                    : ""
                  : dataGroupedOriginal[change.rowId][keyofmapping]
                  ? dataGroupedOriginal[change.rowId][keyofmapping]
                  : "";
              });
            }

            dataTemp[change.rowId] = dictionaryToSet;
            setChangesTracker((prevChanges) => {
              const updatedChanges = { ...prevChanges };
              let newCustomer = change.rowId > maxIDInDB ? true : false;
              const key = `${ActiveEnv},${change.rowId},${newCustomer}`;
              const currentDictionary = updatedChanges[key]
                ? updatedChanges[key]
                : {};
              let newDatesAdded = currentDictionary["newDatesAdded"]
                ? currentDictionary["newDatesAdded"]
                : [];
              let id_changed = currentDictionary["id_changed"]
                ? currentDictionary["id_changed"]
                : [];
              let warehouse_capacity_changed = currentDictionary[
                "warehouse_capacity_changed"
              ]
                ? currentDictionary["warehouse_capacity_changed"]
                : {};
              let old_dictionary = currentDictionary["old_dictionary"] ?? {};
              if (
                Dates.findIndex((values) => values === change.columnId) !== -1
              ) {
                if (
                  datesOriginal.findIndex(
                    (values) => values === change.columnId
                  ) === -1 ||
                  newCustomer
                ) {
                  if (
                    newDatesAdded.findIndex(
                      (value) => value === change.columnId
                    ) === -1
                  ) {
                    newDatesAdded.push(change.columnId);
                  }
                } else {
                  let idDate =
                    dataGroupedOriginal[change.rowId]["warehouse_code"];
                  if (
                    id_changed.findIndex(
                      (value) => value === `${idDate}_${change.columnId}`
                    ) === -1
                  ) {
                    id_changed.push(`${idDate}_${change.columnId}`);
                    old_dictionary[`${idDate}_${change.columnId}`] =
                      dataGroupedOriginal[change.rowId][
                        `Proportion_${change.columnId}`
                      ];
                  }
                }
              } else if (
                newCustomer === false &&
                change.columnId === "Warehouse Name"
              ) {
                let warehouse_capacity_changedtoset =
                  warehouse_capacity_changed[
                    `${dataGroupedOriginal[change.rowId]["warehouse_code"]}`
                  ]
                    ? warehouse_capacity_changed[
                        `${dataGroupedOriginal[change.rowId]["warehouse_code"]}`
                      ]
                    : {};
                warehouse_capacity_changedtoset =
                  dictionaryToSet["warehouse_code"];
                warehouse_capacity_changed[
                  `${dataGroupedOriginal[change.rowId]["warehouse_code"]}`
                ] = warehouse_capacity_changedtoset;
              }
              updatedChanges[key] = { ...dictionaryToSet };
              updatedChanges[key]["newDatesAdded"] = newDatesAdded;
              updatedChanges[key]["id_changed"] = id_changed;
              updatedChanges[key]["warehouse_capacity_changed"] =
                warehouse_capacity_changed;
              updatedChanges[key]["old_dictionary"] = old_dictionary;
              return updatedChanges;
            });
          }
        });
        setDataGrouped(dataTemp);

        const maxIDinEffect = findMaxId(Object.entries(dataTemp), "id");
        setMaxID(maxIDinEffect);

        const newRows = getRows(
          dataTemp,
          dataGroupedOriginal,
          optionType,
          Dates,
          deleteCheckBox,
          deleteCheckBoxColumns,
          showPrevious
        );
        setRows(structuredClone(newRows));
        
        if (slicerCols.length) {
          filterGridRows(
            structuredClone(newRows),
            slicerCols[0].field,
            selectedSlicers
          );
        }

        setEditedCellHighlights((prevHighlights) => {
          const newHighlights = changes.map((change) => {
            return {
              rowId: change.rowId,
              columnId: change.columnId,
              className: "updated-cell",
            };
          });

          return prevHighlights.concat(newHighlights);
        });
      }
    }
  };

  const getColumns = useCallback(
    (data, dates1, deleteCheckBox1, showPrevious) => {
      var dates = dates1.reduce((result, ntr) => {
        if (new Date(ntr) >= new Date()) {
          result.push(ntr);
        } else if (showPrevious) {
          result.push(ntr);
        }
        return result;
      }, []);

      var CustomerMasterRows = [...CustomerMasterRows1, ...dates];
      if (deleteCheckBox1) {
        CustomerMasterRows = ["Delete", ...CustomerMasterRows];
      }
      if (Object.keys(data).length === 0) return [];
      return [
        ...CustomerMasterRows.map((column) => {
          return {
            columnId: column,
            width: column.length * ((column === "Warehouse Name") ? 10 : 15),
            resizable: true,
          };
        }),
      ];
    },
    [CustomerMasterRows1]
  );

  const getRows = useCallback(
    (
      pivotedData,
      dataGroupedOriginal,
      optionType,
      dates1,
      deleteCheckBox1,
      deleteCheckBoxColumns1,
      showPrevious
    ) => {
      var dates = dates1.reduce((result, ntr) => {
        if (new Date(ntr) >= new Date()) {
          result.push(ntr);
        } else if (showPrevious) {
          result.push(ntr);
        }
        return result;
      }, []);

      var CustomerMasterRows = [...CustomerMasterRows1, ...dates];
      if (deleteCheckBox1) {
        CustomerMasterRows = ["Delete", ...CustomerMasterRows];
      }
      const CustomerRowMapping = CustomerRowMapping1;
      dates.forEach((date_value) => {
        CustomerRowMapping[date_value] = "Proportion_" + date_value;
      });
      var maxID1 = 0;
      if (Object.keys(pivotedData).length === 0) return [];
      const headerRowCells = CustomerMasterRows.map((column) => {
        const newColumn = (column === "Warehouse Name") ? column : `Capacity ${column}`;
        return { type: "header", text: `${newColumn}`, className: "header-cell " };
      });
      let rows = [
        {
          rowId: "header",
          cells: [...headerRowCells],
        },
      ];

      let dictionaryRow = [...rows];
      if (deleteCheckBoxColumns1) {
        var headerRowCells1 = CustomerMasterRows1.map((column) => {
          return { type: "header", text: "", className: "header-cell " };
        });

        var datesdeleteCells = dates.map((column) => {
          if (
            column ===
            dates.reduce((max, arr) => {
              if (new Date(max) < new Date(arr)) {
                max = arr;
              }
              return max;
            }, dates[0])
          ) {
            return {
              type: "checkbox",
              checked: false,
              className: "header-cell ",
              nonEditable: true,
            };
          } else {
            return {
              type: "checkbox",
              checked: false,
              className: "header-cell ",
            };
          }
        });

        var combine = [...headerRowCells1, ...datesdeleteCells];
        if (deleteCheckBox1) {
          combine = [
            { type: "header", text: "", className: "header-cell " },
            ...combine,
          ];
        }
        var varDeleteRows = [
          {
            rowId: "delete-header",
            cells: [...combine],
          },
        ];
        dictionaryRow = [...varDeleteRows, ...rows];
      }
      var count = 1;
      Object.entries(pivotedData).forEach(([parentRowName, value1]) => {
        maxID1 = parseInt(value1["id"]);
        let oneSingleRow = {
          rowId: `${parentRowName}`,
          cells: [
            ...CustomerMasterRows.map((value) => {
              if (value === "Delete") {
                return {
                  type: "checkbox",
                  className: "pale-red-bg ",
                  checked: false,
                };
              } else if (
                DropDownRows.findIndex((value1) => value1 === value) !== -1
              ) {
                let dropdownOption =
                  optionType[
                    DropDownMasterColumnsMapping[CustomerRowMapping[value]]
                  ];
                return {
                  type: "dropdown",
                  selectedValue: `${value1[CustomerRowMapping[value]]}`,
                  isOpen: false,
                  values: dataGroupedOriginal[parentRowName]
                    ? dropdownOption.findIndex(
                        (value4) =>
                          value4["label"] ===
                          dataGroupedOriginal[parentRowName][
                            CustomerRowMapping[value]
                          ]
                      ) !== -1
                      ? dropdownOption
                      : [
                          {
                            label: `${
                              dataGroupedOriginal[parentRowName][
                                CustomerRowMapping[value]
                              ]
                            }`,
                            value: `${
                              dataGroupedOriginal[parentRowName][
                                CustomerRowMapping[value]
                              ]
                            }`,
                          },
                          ...dropdownOption,
                        ]
                    : dropdownOption,
                  className: "editable-cell",
                };
              } else if (
                CustomerMasterRows1.findIndex(
                  (value987) => value987 === value
                ) !== -1
              ) {
                return {
                  type: "text",
                  text: `${
                    value1[CustomerRowMapping[value]]
                      ? value1[CustomerRowMapping[value]]
                      : ""
                  }`,
                  className: getCellClassName(SupplySkuNonEditableRows, value),
                  nonEditable:
                    SupplySkuNonEditableRows.findIndex(
                      (value1) => value1 === value
                    ) !== -1,
                };
              } else {
                return {
                  type: "number",
                  value: value1[CustomerRowMapping[value]]
                    ? value1[CustomerRowMapping[value]]
                    : null,
                  className: getCellClassName(SupplySkuNonEditableRows, value),
                  nonEditable:
                    SupplySkuNonEditableRows.findIndex(
                      (value1) => value1 === value
                    ) !== -1,
                };
              }
            }),
          ],
        };
        dictionaryRow.push(oneSingleRow);
        count = count + 1;
      });

      return dictionaryRow;
    },
    [
      CustomerRowMapping1,
      DropDownMasterColumnsMapping,
      DropDownRows,
      SupplySkuNonEditableRows,
      CustomerMasterRows1,
    ]
  );

  const handleColumnResize = (ci, width) => {
    if (Object.keys(dataGrouped).length === 0) return [];
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const handleDeleteCheckbox = () => {
    if (!deleteCheckBox === false) {
      setDeleteTracker((prevData) => {
        prevData["rowstodelete"] = [];
        return prevData;
      });
    }
    setDeleteCheckBox(!deleteCheckBox);
    setColumns(getColumns(dataGrouped, Dates, !deleteCheckBox, showPrevious));
    setRows(
      getRows(
        dataGrouped,
        dataGroupedOriginal,
        optionType,
        Dates,
        !deleteCheckBox,
        deleteCheckBoxColumns,
        showPrevious
      )
    );
  };

  const handleDeleteCheckboxColumns = () => {
    if (!deleteCheckBoxColumns === false) {
      setDeleteTracker((prevData) => {
        prevData["columnstodelete"] = [];
        return prevData;
      });
    }
    setDeleteCheckBoxColumns(!deleteCheckBoxColumns);
    setColumns(getColumns(dataGrouped, Dates, deleteCheckBox, showPrevious));
    setRows(
      getRows(
        dataGrouped,
        dataGroupedOriginal,
        optionType,
        Dates,
        deleteCheckBox,
        !deleteCheckBoxColumns,
        showPrevious
      )
    );
  };

  const handleShowPrevious = () => {
    setShowPrevious(!showPrevious);
    setColumns(getColumns(dataGrouped, Dates, deleteCheckBox, !showPrevious));
    setRows(
      getRows(
        dataGrouped,
        dataGroupedOriginal,
        optionType,
        Dates,
        deleteCheckBox,
        deleteCheckBoxColumns,
        !showPrevious
      )
    );
  };

  const deleteSelectedRows = async () => {
    const rowstodelete = deleteTracker["rowstodelete"];
    let changesTracker1 = { ...changesTracker };
    let dataGrouped1 = dataGrouped;
    var rows_to_database = [];
    var rowstodatabase = [];
    rowstodelete.forEach((value) => {
      if (dataGroupedOriginal[value]) {
        rows_to_database.push({
          warehouse_code: dataGroupedOriginal[value]["warehouse_code"],
        });
        rowstodatabase.push(value);
      }
    });
    if (rows_to_database.length > 0) {
      let data = {};
      data["rowstodeleteindatabase"] = rows_to_database;
      data["deleterow"] = true;
      data["comment"] = comment;
      await deleteData(deleteURL, { method: "DELETE", body: data })
        .then((data) => {
          if (data.Error) return;
        })
        .then(() => {
          let dataGrouped1 = dataGrouped;
          let dataGroupedOriginal1 = dataGroupedOriginal;
          rowstodelete.forEach((value147) => {
            delete dataGrouped1[value147];
            let newData = value147 > maxIDInDB ? true : false;
            delete changesTracker1[`${ActiveEnv},${value147},${newData}`];
            delete dataGroupedOriginal1[value147];
          });
          setDataGrouped(dataGrouped1);
          setDataGroupedOriginal(dataGroupedOriginal1);
          if (rowstodatabase.indexOf(`${maxIDInDB}`) !== -1) {
            setMaxID(findMaxId(Object.entries(dataGrouped1), "id"));
            setMaxIDInDB(findMaxId(Object.entries(dataGroupedOriginal1), "id"));
          }
          setDeleteCheckBox(!deleteCheckBox);
          setColumns(
            getColumns(dataGrouped1, Dates, !deleteCheckBox, showPrevious)
          );
          setRows(
            getRows(
              dataGrouped1,
              dataGroupedOriginal,
              optionType,
              Dates,
              !deleteCheckBox,
              deleteCheckBoxColumns,
              showPrevious
            )
          );
          setChangesTracker(changesTracker1);
        })
        .catch((error) => {
          const toastSettings = {
            description: error,
            status: "error",
            isClosable: true,
            duration: 2000,
          };
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, toastSettings);
          } else {
            toast(toastSettings);
          }
        });
    } else {
      rowstodelete.forEach((value147) => {
        let newData = value147 > maxIDInDB ? true : false;
        delete changesTracker1[`${ActiveEnv},${value147},${newData}`];
        delete dataGrouped1[value147];
      });
      const toastSettings = {
        description: "Data deleted successfully",
        status: "success",
        isClosable: true,
        duration: 2000,
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
      setDeleteCheckBox(!deleteCheckBox);
      setMaxID(findMaxId(Object.entries(dataGrouped1), "id"));
      setDataGrouped(dataGrouped1);
      setColumns(
        getColumns(dataGrouped1, Dates, !deleteCheckBox, showPrevious)
      );
      setRows(
        getRows(
          dataGrouped1,
          dataGroupedOriginal,
          optionType,
          Dates,
          !deleteCheckBox,
          deleteCheckBoxColumns,
          showPrevious
        )
      );
      setChangesTracker(changesTracker1);
    }

    setDeleteTracker((prevData) => {
      prevData["rowstodelete"] = [];
      return prevData;
    });
    setIsAlertBoxOpen(false);
    setAlertBoxType(null);
  };

  const DeleteRowsandColumns = async () => {
    const deleteDates = deleteTracker["columnstodelete"];
    const dates123 = Dates;
    let changesTracker1 = { ...changesTracker };
    deleteDates.forEach((deletevalue) => {
      const indexToRemove = dates123.indexOf(deletevalue);
      if (indexToRemove !== -1) {
        dates123.splice(indexToRemove, 1);
      }
      let changesTrackerModfied = Object.entries(changesTracker1).map(
        ([key, value]) => {
          value["id_changed"] = value["id_changed"].filter(
            (value12) => !value12.includes(deletevalue)
          );
          value["newDatesAdded"] = value["newDatesAdded"].filter(
            (value12) => value12 !== deletevalue
          );
          return [key, value];
        }
      );
      changesTracker1 = Object.fromEntries(changesTrackerModfied);
    });
    var columnstodeleteindatabase = datesOriginal.filter(
      (item) => !dates123.includes(item)
    );
    if (columnstodeleteindatabase.length > 0) {
      let data = {};
      data["columnstodeleteindatabase"] = columnstodeleteindatabase;
      data["deletecolumn"] = true;
      data["comment"] = comment;
      await deleteData(deleteURL, { method: "DELETE", body: data })
        .then((data) => {
          if (data.Error) return;
        })
        .then(() => {
          setDataSaved(true);
          setDatesOriginal([...dates123]);
          setDeleteTracker((prevData) => {
            prevData["columnstodelete"] = [];
            return prevData;
          });
          setDates(dates123);
        })
        .catch((error) => {
          const toastSettings = {
            description: error.response.data.Error,
            status: "error",
            isClosable: true,
            duration: 2000,
          };
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, toastSettings);
          } else {
            toast(toastSettings);
          }
        });
    } else {
      const toastSettings = {
        description: "Data Deleted Successfully",
        status: "success",
        isClosable: true,
        duration: 2000,
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
      setDates(dates123);
    }
    setColumns(getColumns(dataGrouped, dates123, deleteCheckBox, showPrevious));
    setChangesTracker(changesTracker1);
    setRows(
      getRows(
        dataGrouped,
        dataGroupedOriginal,
        optionType,
        dates123,
        deleteCheckBox,
        deleteCheckBoxColumns,
        showPrevious
      )
    );
    setIsAlertBoxOpen(false);
    setAlertBoxType(null);
  };

  const handleDateAddition = (event) => {
    const currentDate1 = new Date(toAddDate + "T00:00:00");

    const maxDate = datesOriginal.reduce((max, arr) => {
      var brr = new Date(arr);
      if (max < brr) {
        max = brr;
      }
      return max;
    }, new Date(datesOriginal[0]));

    if (
      toAddDate === null ||
      toAddDate === "" ||
      currentDate1.getDay() !== 0 ||
      currentDate1 < new Date() ||
      currentDate1 > maxDate
    ) {
      var description = "";

      switch (true) {
        case toAddDate === null || toAddDate === "": {
          description = "Selected Date should not be null";
          break;
        }

        case currentDate1.getDay() !== 0: {
          description = "Selected Date should be a Week Ending";
          break;
        }

        case currentDate1 < new Date(): {
          description = "Selected Date should be in future.";
          break;
        }

        default: {
          description = "Dates should not be greater than maximum date";
        }
      }

      const toastSettings = {
        description: description,
        status: "error",
        isClosable: true,
        duration: 2000,
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
    } else {
      setDates((prevData) => {
        const currentDate = new Date(toAddDate + "T00:00:00");
        const FormatDate = FormatDateToMonthYear1(currentDate);
        if (prevData.findIndex((value) => value === FormatDate) === -1) {
          prevData.push(FormatDate);
        }
        prevData.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });
        setColumns(getColumns(dataGrouped, prevData, showPrevious));
        setRows(
          getRows(
            dataGrouped,
            dataGroupedOriginal,
            optionType,
            prevData,
            deleteCheckBox,
            deleteCheckBoxColumns,
            showPrevious
          )
        );
        return prevData;
      });

      SetToAddDate(null);
    }
  };

  const uploadData = () => {
    setIsButtonDisabled(true);
    let data = [];
    let blankSpace = [];

    Object.entries(changesTracker).forEach(([key, value1]) => {
      const newCustomer = key.split(",")[2];
      if (newCustomer === "false") {
        Object.entries(value1)
          .filter(([key4, value4]) => key4 === "id")
          .forEach(([key, value]) => {
            if (value === null || value === "") {
              blankSpace.push([value1["id"], key]);
            }
          });
      } else {
        Object.entries(value1)
          .filter(([key4, value4]) => key4 === "id")
          .forEach(([key, value]) => {
            if (value === null || value === "") {
              blankSpace.push([value1["id"], key]);
            }
          });
        if (value1["newDatesAdded"].length === 0) {
          blankSpace.push([value1["id"], "Dates"]);
        }
      }
    });

    if (blankSpace.length > 0) {
      let ErrorMsg = "";
      blankSpace.forEach((value) => {
        ErrorMsg =
          ErrorMsg +
          "Blank Space Found for ID " +
          value[0] +
          " for " +
          value[1] +
          ". ";
      });
      const toastSettings = {
        description: ErrorMsg,
        status: "error",
        isClosable: true,
        containerStyle: {
          maxHeight: "300px",
        },
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
    } else {
      Object.entries(changesTracker).forEach(([key, newValue]) => {
        const [, ID, newCustomer] = key.split(",");
        data.push({
          updatedDict: newValue,
          ID: ID,
          newCustomer: newCustomer,
          comment: comment,
        });
      });
      updateData(updateURL, { method: "PUT", body: data })
        .then((data) => {
          if (data.Error) return;
        })
        .then(() => {
          setDataSaved(true);
          setMaxIDInDB(findMaxId(Object.entries(dataGrouped), "id"));
          setDataGroupedOriginal(structuredClone(dataGrouped));
          setDatesOriginal([...Dates]);
          setChangesTracker({});
          setIsAlertBoxOpen(!isAlertBoxOpen);
          setAlertBoxType(null);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
          const toastSettings = {
            description: error.response.data.Error,
            status: "error",
            isClosable: true,
            duration: 2000,
          };
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, toastSettings);
          } else {
            toast(toastSettings);
          }
        });
    }
  };

  /**
   * Add new row handler for ReactGrid
   * @param {*} event Button click event.
   */
  const addNewRowHandler = (event) => {
    const updatedGroupedData = { ...dataGrouped };
    const maxKey = findMaxKey(dataGrouped);
    const newMaxKey = isNaN(maxKey) ? 0 : maxKey + 1;
    const newData = JSON.parse(
      JSON.stringify({ ...updatedGroupedData[maxKey] })
    );
    const optionType = getOptionType(gridData["DropdownMasters"] || {});

    Object.keys(newData).forEach((key) => {
      if (key === "id") {
        newData[key] = newMaxKey;
      } else {
        newData[key] = "";
      }
    });

    updatedGroupedData[newMaxKey] = structuredClone(newData);

    setDataGrouped(updatedGroupedData);
    setDataGroupedOriginal(updatedGroupedData);
    setMaxID(newMaxKey);
    setRows(
      getRows(
        updatedGroupedData,
        updatedGroupedData,
        optionType,
        Object.values(gridData["Dates"]["Dates"]).sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        }),
        deleteCheckBox,
        deleteCheckBoxColumns,
        showPrevious
      )
    );
    setColumns(
      getColumns(
        updatedGroupedData,
        Object.values(gridData["Dates"]["Dates"]).sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        }),
        showPrevious
      )
    );
    setChangesTracker((prevChanges) => {
      const updatedChanges = { ...prevChanges };
      const dictionaryToSend = {};
      const newCustomer = newMaxKey >= maxIDInDB ? true : false;
      const key = `${ActiveEnv},${newMaxKey},${newCustomer}`;

      const existingChanges =
        updatedChanges[`${ActiveEnv},${newMaxKey},${newCustomer}`] || {};
      const dataChanged = existingChanges["DataChanged"] || [];

      dictionaryToSend["DataChanged"] = dataChanged;
      updatedChanges[key] = dictionaryToSend;

      return { ...updatedChanges };
    });

    setTimeout(() => {
      if (gridContainerRef.current) {
        gridContainerRef.current.scrollTop =
          gridContainerRef.current.scrollHeight;
      }
    }, 50);
  };

  /**
   * Reset change handler for ReactGrid
   * @param {*} event  Button click event
   */
  const resetCellChangesHandler = (event) => {
    const optionType = getOptionType(gridData["DropdownMasters"] || {});
    const maxKey = findMaxKey(gridData["ExistingTable"]);
    const newMaxKey = isNaN(maxKey) ? 0 : maxKey + 1;

    setDataGrouped(structuredClone(gridData["ExistingTable"]));
    setDataGroupedOriginal(structuredClone(gridData["ExistingTable"]));
    setDropDownMaster(gridData["DropdownMasters"] || {});
    setOptionType(optionType);
    
    setRows([...rowsCopy]);
    setColumns(
      getColumns(
        gridData["ExistingTable"],
        Object.values(gridData["Dates"]["Dates"]).sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        }),
        showPrevious
      )
    );

    setMaxID(newMaxKey);
    setChangesTracker({});
    setIsButtonDisabled(true);
    setIsAlertBoxOpen(false);
    setAlertBoxType(null);
    setDataSaved({});
    setEditedCellHighlights([]);

    setTimeout(() => {
      if (gridContainerRef.current) {
        gridContainerRef.current.scrollTop = 0;
      }
    }, 50);
  };

  /**
   * Returns slicer values from the grouped data.
   * @param {*} dataGrouped Grouped data.
   * @returns {*} Slicer values.
   */
  const getSlicers = useCallback(
    (dataGrouped) => {
      const dataValues = Object.values(dataGrouped);

      const newSlicers = Object.fromEntries(
        slicerCols.map((e) => [
          e.field,
          Array.from(new Set(dataValues.map((obj) => obj[e.field]))).sort(
            (a, b) => a.localeCompare(b)
          ),
        ])
      );
      return newSlicers;
    },
    [slicerCols]
  );

  /**
   * Filter grid rows based on selected slicer values.
   * @param {any[]} rows Grid row data.
   * @param {string} updatedSlicerKey Slicer key that was updated.
   * @param {*} selectedSlicers Selected slicer values.
   */
  const filterGridRows = (rows, updatedSlicerKey, selectedSlicers) => {
    const filteredRows = [rows[0]];
    const colIndex = slicerCols.find(
      (col) => col.field === updatedSlicerKey
    ).index;

    for (let row of rows.slice(1, rows.length)) {
      const slicerValuesPresent = [];

      slicerValuesPresent.push(
        selectedSlicers[updatedSlicerKey].indexOf(
          row?.cells[colIndex][
            row?.cells[colIndex].type === "dropdown" ? "selectedValue" : "text"
          ]
        ) >= 0
      );

      if (slicerValuesPresent.every((value) => value)) {
        filteredRows.push(row);
      }
    }

    const newSelectedSlicers = { ...selectedSlicers };

    for (let col of slicerCols) {
      if (col.field !== updatedSlicerKey) {
        newSelectedSlicers[col.field] = Array.from(
          new Set(
            filteredRows.slice(1).map((row) => {
              return row?.cells[col.index][
                row?.cells[col.index].type === "dropdown"
                  ? "selectedValue"
                  : "text"
              ];
            })
          )
        );
      }
    }

    setRows([...filteredRows]);
    setSelectedSlicers(structuredClone(newSelectedSlicers));
  };

  useEffect(() => {
    if (!account) {
      navigate("/");
    } else {
      if (ActiveEnv && Object.keys(dataGrouped).length === 0) {
        fetchData(retrieveURL, { method: "GET" }).then((data) => {
          setGridData(structuredClone(data));
          setDataGrouped(data["ExistingTable"]);
          setDataGroupedOriginal(structuredClone(data["ExistingTable"]));
          setDropDownMaster(data["DropdownMasters"]);
          setDates(
            Object.values(data["Dates"]["Dates"]).sort((a, b) => {
              return new Date(a).getTime() - new Date(b).getTime();
            })
          );
          setDatesOriginal(Object.values(data["Dates"]["Dates"]));
          var temp = getOptionType(data["DropdownMasters"]);
          setOptionType(temp);

          const rows = getRows(
            data["ExistingTable"],
            data["ExistingTable"],
            temp,
            Object.values(data["Dates"]["Dates"]).sort((a, b) => {
              return new Date(a).getTime() - new Date(b).getTime();
            }),
            deleteCheckBox,
            deleteCheckBoxColumns,
            showPrevious
          );
          setRows(structuredClone(rows));
          setRowsCopy(structuredClone(rows));
          setColumns(
            getColumns(
              data["ExistingTable"],
              Object.values(data["Dates"]["Dates"]).sort((a, b) => {
                return new Date(a).getTime() - new Date(b).getTime();
              }),
              showPrevious
            )
          );
          const maxIDinEffect = findMaxId(
            Object.entries(data["ExistingTable"]),
            "id"
          );
          setMaxID(maxIDinEffect);
          setMaxIDInDB(maxIDinEffect);
        });
        setDataSaved(true);
      }
    }
  }, [
    account,
    navigate,
    ActiveEnv,
    setDataSaved,
    retrieveURL,
    dataGrouped,
    deleteCheckBox,
    deleteCheckBoxColumns,
    fetchData,
    showPrevious,
    getRows,
    getColumns,
    getOptionType,
  ]);

  // Set / update slicers based on the grouped data
  useEffect(() => {
    const slicers = getSlicers(dataGrouped);
    setSlicers(slicers);
    setSelectedSlicers(slicers);
  }, [dataGrouped, getSlicers]);

  if (error) return <p>Following Error has occurred {error}</p>;
  if (loading || !ActiveEnv)
    return (
      <Container
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          className="Loading-Data"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          boxShadow="md"
          backgroundColor={"white"}
        >
          <CustomSpinner text="Loading Your Data.. Please Wait" />
        </Box>
      </Container>
    );

  return (
    <Flex flex="1" flexDirection="column">
      <Container maxW="container.xxl" py={8}>
        <Stack spacing={8}>
          <Tabs variant="enclosed" bg="white" boxShadow="md">
            <TabList>
              <Tab>
                {
                  prettySheets.filter(
                    (value) => value[0] === parseInt(id_sheet)
                  )[0][1]
                }
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <HStack spacing={3}>
                  <SaveButton
                    isButtonDisabled={isButtonDisabled}
                    uploadData={() => {
                      setIsAlertBoxOpen(!isAlertBoxOpen);
                      setAlertBoxType("Save");
                    }}
                  />

                  <Button
                    style={{ marginTop: "16px" }}
                    size="sm"
                    onClick={resetCellChangesHandler}
                    colorScheme="red"
                    leftIcon={<RxReset />}
                    isDisabled={!Object.keys(changesTracker).length}
                  >
                    Reset changes
                  </Button>

                  <Button
                    style={{ marginTop: "16px" }}
                    size="sm"
                    onClick={onOpen}
                    colorScheme="blue"
                    leftIcon={<BsCardList />}
                  >
                    Change Logs
                  </Button>

                  <Tooltip
                    label={
                      !showPrevious
                        ? "Show Constraints of Past"
                        : "Hide Constraints of Past"
                    }
                    placement="top"
                  >
                    <Button
                      style={{ marginTop: "16px" }}
                      size="sm"
                      onClick={handleShowPrevious}
                      colorScheme="blue"
                      leftIcon={!showPrevious ? <BiShow /> : <BiHide />}
                    >
                      {!showPrevious ? "Show" : "Hide"} Previous
                    </Button>
                  </Tooltip>

                  {slicerCols.length &&
                    slicerCols.map((col, idx) => (
                      <SlicerDropdown
                        key={`slicer-${col.field}-${idx}`}
                        label={col.label}
                        selectedValues={selectedSlicers[col.field]}
                        allValues={slicers[col.field]}
                        onValueChange={(selectedValues) => {
                          const allSelectedSlicers = {
                            ...selectedSlicers,
                            [col.field]: selectedValues,
                          };
                          filterGridRows(
                            rowsCopy,
                            col.field,
                            allSelectedSlicers
                          );
                        }}
                      />
                    ))}

                  {prettySheets && (
                    <Box position="absolute" right="2%">
                      <RegionalLeaderTable
                        name={
                          prettySheets.filter(
                            (value) => value[0] === parseInt(id_sheet)
                          )[0][1]
                        }
                      />
                    </Box>
                  )}
                </HStack>

                <HStack spacing={3}>
                  <Input
                    size="sm"
                    style={{ marginTop: "16px", width: "140px" }}
                    type="Date"
                    value={toAddDate ? toAddDate : ""}
                    onChange={(e) => SetToAddDate(e.target.value)}
                  ></Input>

                  <Button
                    style={{ marginTop: "16px" }}
                    size="sm"
                    isDisabled={!toAddDate || toAddDate === ""}
                    onClick={handleDateAddition}
                    colorScheme="blue"
                    leftIcon={<IoAddCircleOutline />}
                  >
                    Add Date
                  </Button>

                  <Button
                    style={{ marginTop: "16px" }}
                    size="sm"
                    onClick={addNewRowHandler}
                    colorScheme="blue"
                    leftIcon={<IoAddCircleOutline />}
                  >
                    Add Row
                  </Button>

                  {!deleteCheckBox && (
                    <Button
                      style={{ marginTop: "16px" }}
                      size="sm"
                      onClick={handleDeleteCheckbox}
                      colorScheme="red"
                      leftIcon={<RiDeleteRow />}
                    >
                      Delete Rows
                    </Button>
                  )}

                  {deleteCheckBox && (
                    <>
                      <Button
                        style={{ marginTop: "16px" }}
                        size="sm"
                        onClick={() => {
                          setIsAlertBoxOpen(!isAlertBoxOpen);
                          setAlertBoxType("Rows");
                        }}
                        colorScheme="red"
                        leftIcon={<RiDeleteRow />}
                      >
                        Delete Selected Rows
                      </Button>

                      <Button
                        style={{ marginTop: "16px" }}
                        size="sm"
                        onClick={handleDeleteCheckbox}
                        colorScheme="yellow"
                        leftIcon={<MdOutlineCancel />}
                      >
                        Cancel Delete Rows
                      </Button>
                    </>
                  )}

                  {!deleteCheckBoxColumns && (
                    <Button
                      style={{ marginTop: "16px" }}
                      size="sm"
                      onClick={handleDeleteCheckboxColumns}
                      colorScheme="red"
                      leftIcon={<BsCalendar2X />}
                    >
                      Delete Dates
                    </Button>
                  )}

                  {deleteCheckBoxColumns && (
                    <Button
                      style={{ marginTop: "16px", width: "190px" }}
                      size="sm"
                      onClick={() => {
                        setIsAlertBoxOpen(!isAlertBoxOpen);
                        setAlertBoxType("Dates");
                      }}
                      colorScheme="red"
                      leftIcon={<BsCalendar2X />}
                    >
                      Delete Selected Dates
                    </Button>
                  )}

                  {deleteCheckBoxColumns && (
                    <Button
                      style={{ marginTop: "16px", width: "190px" }}
                      size="sm"
                      onClick={handleDeleteCheckboxColumns}
                      colorScheme="yellow"
                      leftIcon={<MdOutlineCancel />}
                    >
                      Cancel Delete Dates
                    </Button>
                  )}
                </HStack>

                <Box minHeight="10vh" paddingBottom={10}>
                  <Box
                    minHeight="10vh"
                    marginBottom="10px"
                    overflow="auto"
                    textAlign="center"
                    id="printableArea"
                    className="react-grid"
                    ref={gridContainerRef}
                  >
                    {/* <ReactGrid rows={rows} columns={columns} onCellsChanged={handleChanges} stickyTopRows={1} stickyLeftColumns={1} disableVirtualScrolling={true} stickyRightColumns={1} onColumnResized={handleColumnResize} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid> */}
                    <div id="reactgrid-red">
                      {/* <ReactGrid rows={rows} columns={columns} stickyTopRows={1} stickyLeftColumns={0} disableVirtualScrolling={false} onColumnResized={handleColumnResize} onCellsChanged={handleChanges} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid> */}
                      <ReactGrid
                        rows={rows}
                        columns={columns}
                        highlights={editedCellHighlights}
                        stickyTopRows={deleteCheckBoxColumns ? 2 : 1}
                        stickyLeftColumns={deleteCheckBox ? 2 : 1}
                        disableVirtualScrolling={false}
                        onColumnResized={handleColumnResize}
                        onCellsChanged={handleChanges}
                        enableColumnSelection
                        enableRangeSelection
                        enableRowSelection
                      ></ReactGrid>
                    </div>
                    <div ref={gridContainerRef}></div>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>

        {isAlertBoxOpen && (
          <CustomAlertDialogBox
            isAlertBoxOpen={isAlertBoxOpen}
            setIsAlertBoxOpen={setIsAlertBoxOpen}
            onChangeCommentHandler={setComment}
            themeColor={alertBoxType === "Save" ? "green" : "red"}
            headerMsg={
              alertBoxType === "Save"
                ? "Please confirm to Save"
                : "Delete Selected " + alertBoxType + "?"
            }
            bodyMsg={"Are you sure? You can't undo this action afterwards."}
            onOKHandler={
              alertBoxType === "Save"
                ? uploadData
                : alertBoxType === "Rows"
                ? deleteSelectedRows
                : alertBoxType === "Dates"
                ? DeleteRowsandColumns
                : null
            }
          />
        )}
        {isOpen && (
          <ChangeLog
            PageName={pathname.split("-")[0]}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        )}
      </Container>
    </Flex>
  );
}
