import {
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import {
  Box,
  Button,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { React, useCallback, useEffect, useState } from "react";
import { BsLock } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { PRODUCT_NAME, VERSION } from "../../Constants";
import { CustomSpinner } from "../Spinner";
import { PublicClientApplication } from '@azure/msal-browser';
import { MSALCONFIG } from "../../Constants";

const Login = () => {
  const { instance, accounts } = useMsal();
  const [account, setAccount] = useState(accounts[0]);
  const navigate = useNavigate();
  const Colour1 = useColorModeValue("bg-surface", "gray.700");
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const msalInstance = new PublicClientApplication(MSALCONFIG);

  const versionTextStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "gray",
    padding: "10px",
  };

  const handleLoginRedirect = useCallback(async () => {
    await instance.handleRedirectPromise().then((result) => {
      if (result && result.account) {
        if (
          result.account.idTokenClaims &&
          result.account.idTokenClaims.roles &&
          (result.account.idTokenClaims.roles.includes("dpplc_user") ||
            result.account.idTokenClaims.roles.includes("Pretty_Litter"))
        ) {
          setIsUnauthorized(false);
          navigate("/CustomerMaster-2");
        } else {
          // instance.logout();
          setIsUnauthorized(true);
        }
      } else {
        console.log("Hi");
      }
      setIsLoading(false);
    });
  }, [instance, navigate]);

  useEffect(() => {
    if (account) {
      if (
        account.idTokenClaims &&
        account.idTokenClaims.roles &&
        (account.idTokenClaims.roles.includes("dpplc_user") ||
          account.idTokenClaims.roles.includes("Pretty_Litter"))
      ) {
        navigate("/CustomerMaster-2");
      } else {
        // instance.logout();
        setIsUnauthorized(true);
      }
    } 
    else{
      handleLoginRedirect()
    }
    setIsLoading(false);
    // handleLoginRedirect();
  }, [setIsLoading, account, navigate, handleLoginRedirect]);

  const handleLogin = async () => {
    try {
      await msalInstance.loginRedirect({
        prompt:"select_account",
        scopes: ["user.read"]
    }).catch(e => {
        console.error(e);
    });
    handleLoginRedirect()
    
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleUnauthorizedAccount = async () => {
    sessionStorage.clear();
    localStorage.clear();
    await handleLogin();
  };

  const loadingSpinnerBox = (
    <Box
      className="login-form"
      maxW="md"
      mx="auto"
      p={6}
      borderRadius="xl"
      bg={Colour1}
      boxShadow="md"
    >
      <CustomSpinner text="Please wait..." />
    </Box>
  );

  const loginBox = (
    <Box
      className="login-form"
      maxW="md"
      mx="auto"
      p={6}
      borderRadius="xl"
      bg={Colour1}
      boxShadow="md"
    >
      <Text fontSize="3xl" fontWeight="bold" textAlign="center" mb={6}>
        {PRODUCT_NAME}
      </Text>
      <Stack spacing={6}>
        <Button
          type="submit"
          onClick={handleLogin}
          colorScheme="teal"
          size="lg"
          leftIcon={<BsLock />}
        >
          Sign in With Microsoft
        </Button>
      </Stack>
      <Text style={versionTextStyle}>Version {VERSION}</Text>
    </Box>
  );

  const unauthorizedBox = (
    <Box
      className="login-form"
      maxW="md"
      mx="auto"
      p={6}
      borderRadius="xl"
      bg={Colour1}
      boxShadow="md"
    >
      <Text fontSize="3xl" fontWeight="bold" textAlign="center" mb={6}>
        {PRODUCT_NAME}
      </Text>
      <Text
        fontSize="20px"
        fontWeight="bold"
        textAlign="center"
        mb={6}
        color="red"
      >
        Your account is unauthorized. Access is denied.
      </Text>
      <Stack spacing={6}>
        <Button
          type="submit"
          onClick={handleUnauthorizedAccount}
          colorScheme="blue"
          size="lg"
          leftIcon={<BsLock />}
        >
          Login with a different account
        </Button>
      </Stack>
      <Text style={versionTextStyle}>Version {VERSION}</Text>
    </Box>
  );

  return (
    <Container
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {isLoading && loadingSpinnerBox}
      {!isLoading && !isUnauthorized && loginBox}
      {!isLoading && isUnauthorized && unauthorizedBox}
    </Container>
  );
};

export default Login;
