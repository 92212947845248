import React from 'react';
import { Spinner, Box } from '@chakra-ui/react';

export function CustomSpinner({ text }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
        mr={2}
      />
      <span>{text}</span>
    </Box>
  );
}