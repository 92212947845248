import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Textarea } from "@chakra-ui/react";
import React, { useRef } from "react";

const CustomAlertDialogBox = ({
  isAlertBoxOpen, setIsAlertBoxOpen, themeColor, headerMsg, bodyMsg, onOKHandler, onChangeCommentHandler
}) => {
  const onAlertBoxClose = () => setIsAlertBoxOpen(false);
  const alertBoxCancelRef = useRef();
  const onCommentChange = ((e) => {  
    onChangeCommentHandler(e.target.value);
  })

  return (
    <>
      <AlertDialog
        isOpen={isAlertBoxOpen}
        onClose={onAlertBoxClose}
        leastDestructiveRef={alertBoxCancelRef}
        motionPreset="scale"
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {headerMsg}
            </AlertDialogHeader>
            
            <Textarea placeholder='Please Enter Description for Changes' size="sm" maxWidth="80%" height="10vh" marginX="24px" rounded={10}  onChange={onCommentChange}/>

            <AlertDialogBody>
              {bodyMsg}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={alertBoxCancelRef} onClick={onAlertBoxClose}>
                Cancel
              </Button>
              <Button colorScheme={themeColor}  onClick={onOKHandler} ml={3}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default CustomAlertDialogBox;
