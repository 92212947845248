import DropDownTables from "./WarehouseSpaceContentTabPanel";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Flex, TabPanels,Box } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react";
import { Tabs } from "@chakra-ui/react";
import { Tab } from "@chakra-ui/react";
import { TabList, Stack } from "@chakra-ui/react";
import useFetch from "../../../../hooks/useFetchWithMsal";
import { CustomSpinner } from "../../../Spinner";

export default function SingleTabPanel({
  setDataSaved,
  ActiveEnv,
  CustomerMasterRows,
  CustomerRowMapping,
  SupplySkuNonEditableRows,
  SupplySkuColumnsDatabase,
  DropDownRows,
  DropDownMasters,
  DropDownMasterColumnsMapping,
  dependenciesMapping,
  blankRows = [],
  DropDownMasterIntRows,
  retrieveURL,
  updateURL,
  deleteURL,
  deletenabled = true,
  warehouseCalculatedColumns = [],
  warehouseCalculatedColumnsMapping = {},
  warehouseCalculatedColumnsOperations = {},
  ItemMasterGroupPolicy = {},
  additionalNotes = [],
  sortByFields = [],
  slicerColumns = [],
  disableVirtualScrolling = false
}) {
  const prettySheets = JSON.parse(sessionStorage.getItem("prettySheets"));
  const { pathname } = useLocation();
  const [id_sheet] = useState(pathname ? parseInt(pathname.split("-")[1]) : 0);
  const [{  loading, error }, fetchData] = useFetch();

  return(
  <Flex flex="1" flexDirection="column">
     <Container
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        hidden={!(loading||!ActiveEnv)}
      >
        <Box
          className="Loading-Data"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          boxShadow="md"
          backgroundColor={"white"}
        >
          <CustomSpinner text="Loading Your Data.. Please Wait" />
        </Box>
    </Container>
    
    <Container maxW="container.xxl" py={8} hidden={loading}>
      <Stack spacing={8}>
        <Tabs variant="enclosed" bg="white" boxShadow="md">
          <TabList>
            <Tab>
              {
                prettySheets && prettySheets.filter(
                  (value) => value[0] === parseInt(id_sheet)
                )[0][1]
              }
            </Tab>
          </TabList>
          <TabPanels>
            <DropDownTables
              setDataSaved={setDataSaved}
              ActiveEnv={ActiveEnv}
              CustomerMasterRows={CustomerMasterRows}
              CustomerRowMapping={CustomerRowMapping}
              SupplySkuNonEditableRows={SupplySkuNonEditableRows}
              SupplySkuColumnsDatabase={SupplySkuColumnsDatabase}
              DropDownRows={DropDownRows}
              DropDownMasters={DropDownMasters}
              DropDownMasterColumnsMapping={DropDownMasterColumnsMapping}
              dependenciesMapping={dependenciesMapping}
              DropDownMasterIntRows={DropDownMasterIntRows}
              blankRows={blankRows}
              retrieveURL={retrieveURL}
              updateURL={updateURL}
              deleteURL={deleteURL}
              deletenabled={deletenabled}
              warehouseCalculatedColumns={warehouseCalculatedColumns}
              warehouseCalculatedColumnsOperations={
                warehouseCalculatedColumnsOperations
              }
              warehouseCalculatedColumnsMapping={
                warehouseCalculatedColumnsMapping
              }
              ItemMasterGroupPolicy={ItemMasterGroupPolicy}
              additionalNotes={additionalNotes}
              sortByFields={sortByFields}
              slicerColumns={slicerColumns}
              id_sheet={id_sheet}
              pathname={pathname}
              fetchData={fetchData}
              disableVirtualScrolling={disableVirtualScrolling}
            />
            </TabPanels>
        </Tabs>
      </Stack>
    </Container>
  </Flex>
  )
};
